import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  async mounted() {
    var label =[]
    var orders = []
    await this.$store.dispatch('admin/chart').then((res)=>{
      console.log(res.data.data.order)
      label = res.data.data.order.days
      orders =  res.data.data.order.count
    })
    this.renderChart({
      labels:label,
      datasets: [
        {
          label: 'Orders',
          backgroundColor: [
            '#836BA8',
          ],

          data: orders
        },
      ]
    }, { responsive: true, maintainAspectRatio: false })
  }
}