<template>
    <div class="chart">
        <div class="col-sm-12">
            <LineChart/>
        </div>
            
        
    </div>
</template>

<script>
import LineChart from './LineChart'
export default {
    components:{
        LineChart
    },
    name:"Chart",
}
</script>

<style scoped>
.Chart {
    padding: 20px;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, .4);
    border-radius: 20px;
    margin: 50px 0;
  }
</style>