<template>
  <div>
    <div class="row">
      <div class="col-xl-3">
        <div class="card card-custom bg-primary card-stretch gutter-b">
          <div class="card-body">
            <span class="svg-icon svg-icon-2x svg-icon-white">
              <i class="far fa-clock text-white" style="font-size: 20px"> </i>
            </span>
            <span
              class="card-title font-weight-bolder text-white font-size-h2 mb-0 mt-6 d-block"
              >{{admin.queues}}</span
            >
            <span class="font-weight-bold text-white font-size-sm">{{$t('Qeues')}}</span>
          </div>
        </div>
      </div>
      <div class="col-xl-3">
        <div class="card card-custom bg-primary card-stretch gutter-b">
          <div class="card-body">
            <span class="svg-icon svg-icon-2x svg-icon-white">
              <i
                class="fas fa-user-friends text-white"
                style="font-size: 20px"
              ></i>
            </span>
            <span
              class="card-title font-weight-bolder text-white font-size-h2 mb-0 mt-6 d-block"
              >{{admin.clients}}</span
            >
            <span class="font-weight-bold text-white font-size-sm"
              >{{$t('Clients')}}</span
            >
          </div>
        </div>
      </div>

      <div class="col-xl-3">
        <div class="card card-custom bg-primary card-stretch gutter-b">
          <div class="card-body">
            <span class="svg-icon svg-icon-2x svg-icon-white">
              <i class="fas fa-car text-white" style="font-size: 20px"></i>
            </span>
            <span
              class="card-title font-weight-bolder text-white font-size-h2 mb-0 mt-6 d-block"
              >{{admin.drivers}}</span
            >
            <span class="font-weight-bold text-white font-size-sm"
              >{{$t('Drivers')}}</span
            >
          </div>
        </div>
      </div>

      <div class="col-xl-3">
        <div class="card card-custom bg-primary card-stretch gutter-b">
          <div class="card-body">
            <span class="svg-icon svg-icon-2x svg-icon-white">
              <i
                class="fas fa-shopping-cart text-white"
                style="font-size: 20px"
              ></i>
            </span>
            <span
              class="card-title font-weight-bolder text-white font-size-h2 mb-0 mt-6 d-block"
              >{{admin.orders}}</span
            >
            <span class="font-weight-bold text-white font-size-sm">{{$t('Orders')}}</span>
          </div>
        </div>
      </div>

      <div class="col-xl-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">Orders</div>
          </div>
          <div class="card-body">
            <div>
              <Chart />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapState } from "vuex";
import Chart from "../Charts/Chart";
export default {
  components: {
    Chart,
  },
  data: function () {
    return {};
  },
  computed:{
    admin() {
      return this.admin
    },
    ...mapState({
      admin: (state) => state.admin.admin,
    }),
  },
  beforeMount() {
    /*if (localStorage.getItem("type") == "client") {
      this.$router.push({ path: "/Clients/dashboard" });
    }*/

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard", route: "" }]);
    this.$store.dispatch('admin/dashboard')

    
  },
};
</script>
